.App {
  text-align: center;
  height: 100vh;
  background-color: #5d7094;
}

.App-container {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.App-item {
  padding: 10px;
}

.App-card {
  background-color: #fff;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  display: flex;
}

.App-header {
  width: 100%;
  height: 42px;
  display: flex;
}

.App-select {
  flex: 1 1;
  position: relative;
}

.App-select > select {
  border: 0;
  background-color: transparent;
  border-right: 1px solid #bbb;
  padding: 0 28px 0 16px;
  border-radius: 0;
  width: 100%;
  appearance: none;
  height: 100%;
  outline: none;
  font-size: 16px;
  cursor: pointer;
}

.App-select:after {
  content: "";
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  border-top: 5px solid #000;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

.App-button {
  outline: none;
  border: 0;
  background-color: transparent;
  padding: 0 16px;
  height: 100%;
  cursor: pointer;
  font-size: 16px;
  position: relative;
}

.App-button:hover,
.App-select:hover {
  background-color: #ddd;
}

.App-right {
  box-sizing: border-box;
  height: calc(100% - 62px);
  flex: 0 0 66.66%;
}

.App-left {
  box-sizing: border-box;
  height: calc(100% - 62px);
  flex: 0 0 33.33%;
}
