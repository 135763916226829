.flowchart-block {
    fill: #eee;
    stroke: #000;
    stroke-width: 1px;
}

.execute .flowchart-block {
    stroke: #cc0;
    filter: url(#executeFilter);
    font-size: 20px;
}

.flowchart-text {
    fill: #000;
    font-family: arial;
}

.flowchart-arrow {
    stroke: #000;
    stroke-width: 1px;
}

.flowchart-terminal-text {
    font-size: 30px;
}